import 'hammerjs'

function hideHomeGridOpenVideos() {
  $('.thumb video').hide();
  $('.thumb .device-tip').show();
}

function showWantedHomeGridVideo($thumb) {
  let $video = $thumb.find('video');
  let src = $video.attr('data-src');
  if (!src) return;
  $video.attr('src', src);
  $thumb.find('.device-tip').hide();
  $video.show();
}

function toggleHomeGridVideoOn($thumb) {
  $('.thumb video').not($thumb.find('video')).hide();
  $('.thumb .device-tip').not($thumb.find('.device-tip')).show();

  showWantedHomeGridVideo($thumb);
}

$('.thumb').mouseenter(function (e) {
  toggleHomeGridVideoOn($(this));
}).mouseleave(function () {
  hideHomeGridOpenVideos();
}).each(function (i, thumb) {
  let mc = new Hammer(thumb);
  mc.on('press', function () {
    toggleHomeGridVideoOn($(thumb));
  });
  mc.on('pan', function () {
    toggleHomeGridVideoOn($(thumb));
  });
})