import * as debounce from "debounce";

require('components/video_rollover');
require('slick-carousel')

$(function () {
    $('.slick-slider--promo-spot').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        centerMode: false,
        rows: 0,
        arrows: false,
        variableWidth: true,
        focusOnSelect: false,
        focusOnChange: false,
        dots: false,
        infinite: false,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 655,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 3,
                    arrows: true
                }
            }
        ]
    });
});
